.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
    color: rgba(13, 185, 123, 1);
    transform: scale(1.1);
    font-weight: 800;
}

div.nav-item {
    width: 6em;
}

div.nav-item>a.nav-link {
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}