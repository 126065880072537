@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap&family=Dongle&display=swap');

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(15, 12, 12, 1);
}

.theme-dark {
  --bs-body-color: var(--bs-white);
  /* --bs-body-bg: rgba(15, 12, 12, 1); */

  /* --foot-head-color: #203a44;
  --paragraph-color: rgb(151 151 151 / 100%);
  --text1-color: rgb(255 255 255); */
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

/* adjust spinner */
.spinner-grow {
  width: inherit;
  height: inherit;
  background-color: inherit;
}

.accordion-item .accordion-button,.accordion-item .accordion-body {
  background-color: var(--bs-body-bg);
  color: black
}
.accordion-item .accordion-body {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

#token-name {
  width: 100%;
  word-break: break-word;
  font-family: 'Montserrat Alternates', sans-serif;
  text-align: center;
  color: tan
}

/* .price {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 4.1em;
  right: 3em;
  z-index: 100;
  border-radius: 15px 0 0 15px;
  padding: 5px 10px;
  color: var(--bs-body-color)
} */

button#success {
  background-color: #E0E0E0;
  border-radius: 5px;
  border-width: 0;
  height: 3em;
  padding: 0 1em;
  color: rgb(122, 122, 122);
  /* text-align: inherit; */
  font-weight: 700;
}

input:focus {
  outline: none !important;
}

.btn-primary {
  background-color: linear-gradient(270deg, rgb(63, 60, 246), rgb(65, 63, 181));
  background-image: linear-gradient(rgba(255, 255, 230, 0.95), rgba(255, 255, 255, 0.2) 49%, rgba(0, 0, 0, 0.15) 51%, rgba(0, 0, 0, 0.05));
}

#search::placeholder {
  color: rgb(192, 192, 192);
  font-size: 90%;
  padding: 0 1em;
}

.collections-tab {
  color: white;
}

.form-control {
  background-color: rgb(224, 224, 224);
  box-shadow: rgba(255, 255, 255, 0.78) -4px -4px 11px inset, rgba(0, 0, 0, 0.25) 4px 4px 11px inset;
  border-radius: 50px;
}


@media screen and (min-width: 993px) {
  div#wallet-connect {
    display: flex !important;
  }
}

@import '~bootstrap/dist/css/bootstrap.css';