.wallet-link {
    word-break: break-all;
}

#contained-modal-title-vcenter {
    background-color: inherit;
    color: white;
}

#trx-connect-svg {
    width: 4em;
    align-self: center;
}

.mod-header {
    background-color: #1b3d4c;
}

.mod-body,
.mod-footer {
    background-color: #e0e0e0;
}