button#my-nft-btns {
    background-color: #E0E0E0;
    border-radius: 5px;
    border-width: 0;
    /* box-shadow: rgb(0 0 0 / 26%) 2px 2px 3px 2px, rgb(255 255 255 / 75%) 0px 0px 4px 3px; */
    width: 100%;
    height: 3em;
    padding: 0 1em;
    color: rgb(122, 122, 122);
    /* text-align: inherit; */
    font-weight: 700;
}

button#buy-btn {
    width: 50%;
    background-color: rgba(13, 185, 123, 1);
    margin-bottom: 3px;
    position: relative;
    border-radius: 8px;
    height: 2em;
    color: white;
    font-size: 24px;
    border: none;
}

button#my-nft-btn {
    width: 100%;
    background-color: rgba(13, 185, 123, 1);
    position: relative;
    border-radius: 8px;
    height: 2.3em;
    color: white;
    font-size: 24px;
    border: none;
  }
  
  #my-nft-btn-Sell{
    width: 100%;
    position: relative;
    border-radius: 8px;
    height: 2.3em;
    color: white;
    font-size: 24px;
    border: none;
    background-color: rgb(188, 83, 83);
  }
  
  #my-nft-btn-Send{
    width: 100%;
    position: relative;
    border-radius: 8px;
    height: 2.3em;
    color: white;
    font-size: 24px;
    border: none;
    background-color: rgba(13, 185, 123, 1);
  }
  
  #my-nft-btn-Bonus{
    width: 100%;
    position: relative;
    border-radius: 8px;
    height: 2.3em;
    color: black;
    font-size: 24px;
    border: none;
    background-color: yellow;
  }
  
  .mod-body,
  .mod-footer {
    background-color: rgba(15, 12, 12, 1);
    color: var(--bs-body-color);
  }
  
  .mod-header {
    background: linear-gradient(171.27deg, #343A3C 19.05%, #3B4145 46.1%);
  }

.inner-container-dark {
    background-image: linear-gradient(rgba(50, 49, 60, 0.67), rgba(25, 46, 70, 0.58)),
    radial-gradient(circle 300px at 35% 30%, #5C9CC0, rgb(78, 78, 78))
    /* , radial-gradient(circle 300px at bottom, #4D5EFF 20%, rgb(78, 78, 78)) */;
    border-radius: 8px;
    border: 1px solid #657EAE;
    box-shadow: 77px 4px 44px rgba(0, 0, 0, 0.7);
}
